import { defineComponent, reactive, toRefs, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "LAYOUT",

  setup() {
    const router = useRouter();
    const state = reactive({
      active: "/home",
      includeRoutes: ["ABOUT"]
    }); // 监听 active 变化时同步更新路由

    watch(() => state.active, val => {
      if (val !== router.currentRoute.value.path) {
        router.push(val); // 路由更新时，确保同步到当前活动的路径
      }
    }); // 监听页面初始加载时的路由

    onMounted(() => {
      state.active = router.currentRoute.value.path;
    });
    return { ...toRefs(state)
    };
  }

});